import "../PropertyPage.css"
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"

//photo imports:
import Barn from "./RoadCreekAssets/Barn.jpg"
import Barn2 from "./RoadCreekAssets/Barn2.jpg"
import Barn3 from "./RoadCreekAssets/Barn3.jpg"
import Creek1 from "./RoadCreekAssets/Creek1.jpg"
import Creek2 from "./RoadCreekAssets/Creek2.jpg"
import Creek3 from "./RoadCreekAssets/Creek3.jpg"
import Creek4 from "./RoadCreekAssets/Creek4.jpg"
import Creek5 from "./RoadCreekAssets/Creek5.jpg"
import Creek6 from "./RoadCreekAssets/Creek6.jpg"
import Creek7 from "./RoadCreekAssets/Creek7.jpg"
import Kitchen from "./RoadCreekAssets/Kitchen.jpg"
import Oak1 from "./RoadCreekAssets/Oak1.jpg"
import Oak2 from "./RoadCreekAssets/Oak2.jpg"
import Oak3 from "./RoadCreekAssets/Oak3.jpg"
import Pond from "./RoadCreekAssets/Pond.jpg"


const RoadCreekPage = () => {

    const images = [
        {   
            original: Creek5,
            thumbnail: Creek5,
        },
        {   original: Creek1,
            thumbnail: Creek1,
        },
        { 
            original: Creek2,
            thumbnail: Creek2,
        },

        {
            original: Creek3,
            thumbnail: Creek3,
        },
        {
            original: Creek4,
            thumbnail: Creek4,
        },

        { 
            original: Creek6,
            thumbnail: Creek6,
        },

        {
            original: Creek7,
            thumbnail: Creek7,
        },
        {
            original: Oak1,
            thumbnail: Oak1,
        },
        {   
            original: Oak2,
            thumbnail: Oak2,
        },
        { 
            original: Oak3,
            thumbnail: Oak3,
        },

        {
            original: Pond,
            thumbnail: Pond,
        },
        {
            original: Barn,
            thumbnail: Barn,
        },
        { 
            original: Barn2,
            thumbnail: Barn2,
        },

        {
            original: Barn3,
            thumbnail: Barn3,
        },
        {
            original: Kitchen,
            thumbnail: Kitchen,
        },
        
    ]

    return ( 
        <div className = "ppcontainer">
            <div className="ppchild propertynameandloc">
                <h1 class="decorated-title">Road Creek</h1>
                <p>319 Acres in Goliad County</p>
            </div>
            <div>
            <h2> Photo Gallery </h2>
                <div className="ppchild">
                    {/* <h3 className="imagechild"> Image Gallery </h3> */}
                    <ImageGallery items={images}
                    showPlayButton = {false}
                    showFullscreenButton = {true}/> 
                </div>
                <h2> About</h2>
                <div className="ppchild">
                    <div className="ppchild">
                        <h3>Description:</h3>
                        <p>This is a very scenic and productive wildlife ranch covered in thick live oaks and wildlife brush.   The ranch is easy to get to only 20 minutes from Victoria and 10 minutes from Goliad and located in the Goliad ISD.   Road Creek passes through the ranch and holds water year round.   The creek is lined with live oak, post oak, and elm trees.   The terrain is gently rolling and very scenic.   Low fenced on three sides and high game fenced on one side.   Excellent Development potential in smaller tracts.</p>
                    </div>
                    <div className="ppchild">
                        <h3 >Location:</h3>
                        <p>Located approximately 6 miles North East of Goliad, in Goliad County.   The ranch is accessed by a paved county road.</p>
                    </div> 
                    <div className="ppchild">
                        <h3 >Improvements:</h3>
                        <p>In 2022 the owner recently finished a brand new barn with an apartment on the property.   The metal building has ATV storage on one side and a covered RV area on another side.</p>
                    </div>
                    <div className="ppchild">
                        <h3 >Water:</h3>
                        <p>The ranch is very well watered with two water wells two stock tanks and a lot of  year round water in Road Creek.   There are two water lines installed to the stock tanks and two water lines installed to Road Creek.   The addition of the supplemental water lines to the water features all the owner to maintain a constant level of water in the creek and ponds.  One of the water wells was drilled new in 2022 and both wells have new pumps installed in 2022.</p>
                    </div>
                    <div className="ppchild">
                        <h3 >Game:</h3>
                        <p>Deer, hogs and Turkey, great dove hunting.</p>
                    </div>                      
                </div>
            </div> 
        </div>
     );
}
 
export default RoadCreekPage;