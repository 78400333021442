import React from "react";
import { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import "./NavBar.css";

const Navbar = () => {
  const { logoutUser, user } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <html>
    <head>
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link href="https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&family=Ms+Madi&display=swap" rel="stylesheet"/>
    </head>
    <header>
      <div className="navBar">
        <ul>
          <li className="brand">
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            <p className="title top">T L B </p><p className="title bottom">&nbsp;Properties</p>
            </Link>
          </li>
          <li>
            {user ? (
              <button onClick={logoutUser}>LOG OUT</button>
            ) : (
              <button onClick={() => navigate("/login")}>LOG IN</button>
            )}
          </li>
        </ul>
      </div>         
    </header>

    </html>

  );
};

export default Navbar;
