import React from "react";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate, Link} from "react-router-dom";
import "./HomePage.css"
import axios from "axios";
import config from "../../config/config";

//Thumbnail Imports
import TCThumbnail from "./HomePageAssets/TCThumbnail.jpg"
import ROThumbnail from "./HomePageAssets/ROThumbnail.jpg"
import RCThumbnail from "./HomePageAssets/RCThumbnail.jpg"
import PAThumbnail from "./HomePageAssets/PAThumbnail.jpeg"


const HomePage = () => {
  // The "user" value from this Hook contains the decoded logged in user information (username, first name, id)
  // The "token" value is the JWT token that you will send in the header of any request requiring authentication
  //TODO: Add an AddCars Page to add a car for a logged in user's garage

  const [user, token] = useAuth();  
  const [properties, setProperties] = useState([]);   
  const navigate = useNavigate();



  useEffect(() => {
    const fetchProperties = async () => {
      try {
        let response = await axios.get( config.apiURL + "/api/properties/all/", {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        setProperties(response.data);
      } catch (error) {
        console.log(error.response.data);
      }
    };
    fetchProperties();
  }, [token]);


 

//   return (
//     <div className="container">
//     {properties &&
//       properties.map((property) => (
//         <div className = "ppchild" key={property.id}>
//           <img src={`https://127.0.0.1:8000${property.thumbnail}`} alt={property.name} width="400" height="300" />
//           <p>
//             <a href={`/property/${property.id}`} key={property.id}>
//             {property.name} {property.location}
//             </a>
//             </p>          

//         </div>
//       ))}
//   </div>
// );
return (
  <html>
    <head>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Ms+Madi&display=swap" rel="stylesheet" />
    </head>
    <body>

{/* Turtle Creek */}
<div className="hpcontainer">
          <div className = "hpchild" > 
          <Link to ='/turtlecreek' style = {{ color: 'black', textDecoration: 'none' }}>
            <div >
              <img className = "thumbnailimage" src={TCThumbnail} alt= {"Turtle Creek Thumbnail"}/>
              <div className="titlecontainer">
                <p className = "hptitle titlechild">Turtle Creek</p>
                <p className = "hplocation titlechild">Kerrville, Texas</p>                  
              </div>
            </div>
          </Link>
          </div> 
{/* Port Aransas
          <div className = "hpchild" > 
          <Link to ='/portaransas' style = {{ color: 'black', textDecoration: 'none' }}>
            <div >
              <img className = "thumbnailimage" src={PAThumbnail} alt={"Port Aransas Thumbnail"} />
              <div className="titlecontainer">
                <p className = "hptitle titlechild">Port A House</p>
                <p className = "hplocation titlechild">Port Aransas, Texas</p>                  
              </div>
            </div>
          </Link>
          </div>  */}
{/* Road Creek */}
          <div className = "hpchild" > 
          <Link to ='/roadcreek' style = {{ color: 'black', textDecoration: 'none' }}>
            <div >
              <img className = "thumbnailimage" src={RCThumbnail} alt={"Road Creek Thumbnail"} />
              <div className="titlecontainer">
                <p className = "hptitle titlechild">Road Creek</p>
                <p className = "hplocation titlechild">Road Creek, Texas</p>                  
              </div>
            </div>
          </Link>
          </div>   
{/* Rolling Oaks */}
          <div className = "hpchild" > 
          <Link to ='/rollingoaks' style = {{ color: 'black', textDecoration: 'none' }}>
            <div >
              <img className = "thumbnailimage" src={ROThumbnail} alt={"Rolling Oaks Thumbnail"} />
              <div className="titlecontainer">
                <p className = "hptitle titlechild">Rolling Oaks</p>
                <p className = "hplocation titlechild">Rolling Oaks, Texas</p>                  
              </div>
            </div>
          </Link>
          </div>
{/* Medio Valley
          <div className = "hpchild" > 
          <Link to ='/mediovalley' style = {{ color: 'black', textDecoration: 'none' }}>
            <div >
              <div className="titlecontainer">
                <p className = "hptitle titlechild">Medio Valley</p>
                <p className = "hplocation titlechild">Medio Valley, Texas</p>                  
              </div>
            </div>
          </Link>
          </div> */}
                 
</div>        
    </body>
   
  </html>

);
}

export default HomePage;
