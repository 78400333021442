import "../PropertyPage.css"
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"


//photo imports:
import Bathroom from "./RollingOaksAssets/Bathroom.jpg"
import Bathroom2 from "./RollingOaksAssets/Bathroom2.jpg"
import Brush1 from "./RollingOaksAssets/Brush1.jpg"
import Closet from "./RollingOaksAssets/Closet.jpg"
import Cows from "./RollingOaksAssets/Cows.jpg"
import Cows2 from "./RollingOaksAssets/Cows2.jpg"
import Field from "./RollingOaksAssets/Field.jpg"
import Garage from "./RollingOaksAssets/Garage.jpg"
import Gate from "./RollingOaksAssets/Gate.jpg"
import House1 from "./RollingOaksAssets/House1.jpg"
import House2 from "./RollingOaksAssets/House2.jpg"
import House3 from "./RollingOaksAssets/House3.jpg"
import House4 from "./RollingOaksAssets/House4.jpg"
import Kitchen from "./RollingOaksAssets/Kitchen.jpg"
import Kitchen2 from "./RollingOaksAssets/Kitchen2.jpg"
import LivingRoom from "./RollingOaksAssets/LivingRoom.jpg"
import LivingRoom2 from "./RollingOaksAssets/LivingRoom2.jpg"
import Pond1 from "./RollingOaksAssets/Pond1.jpg"
import Pond2 from "./RollingOaksAssets/Pond2.jpg"
import Pond3 from "./RollingOaksAssets/Pond3.jpg"
import Pond4 from "./RollingOaksAssets/Pond4.jpg"
import Pond5 from "./RollingOaksAssets/Pond5.jpg"
import PondHouse from "./RollingOaksAssets/PondHouse.jpg"
import Porch from "./RollingOaksAssets/Porch.jpg"
import Trees from "./RollingOaksAssets/Trees.jpg"


const RollingOaksPage = () => {

    const images = [

        {   original: Pond1,
            thumbnail: Pond1,
        },
        { 
            original: Brush1,
            thumbnail: Brush1,
        },

        {
            original: Cows,
            thumbnail: Cows,
        },
        {
            original: Cows2,
            thumbnail: Cows2,
        },
        {
            original: Field,
            thumbnail: Field,
        },

        {
            original: Gate,
            thumbnail: Gate,
        },

        {
            original: Pond2,
            thumbnail: Pond2,
        },
        {
            original: Pond3,
            thumbnail: Pond3,
        },
        {
            original: Pond4,
            thumbnail: Pond4,
        },
        {
            original: Pond5,
            thumbnail: Pond5,
        },
        {
            original: PondHouse,
            thumbnail: PondHouse,
        },
        {
            original: House1,
            thumbnail: House1,
        },
        {
            original: House2,
            thumbnail: House2,
        },
        {
            original: House3,
            thumbnail: House3,
        },        
        {
            original: House4,
            thumbnail: House4,
        },
        {
            original: Kitchen,
            thumbnail: Kitchen,
        },
        {
            original: Kitchen2,
            thumbnail: Kitchen2,
        },
        {
            original: LivingRoom,
            thumbnail: LivingRoom,
        },
        {
            original: LivingRoom2,
            thumbnail: LivingRoom2,
        },        
        {
            original: Garage,
            thumbnail: Garage,
        },
        {
            original: Porch,
            thumbnail: Porch,
        },
        {
            original: Trees,
            thumbnail: Trees,
        },        
        {
            original: Bathroom,
            thumbnail: Bathroom,
        },
        {
            original: Bathroom2,
            thumbnail: Bathroom2,
        },        
        {
            original: Closet,
            thumbnail: Closet,
        },
    ]

    return ( 
        <div className = "ppcontainer">
            <div className="ppchild propertynameandloc">
                <h1 class="decorated-title">Rolling Oaks</h1>
                <p>100 Acres in Kimble County</p>
            </div>
                <h2> Photo Gallery </h2>
                <div className="ppchild">
                    {/* <h3 className="imagechild"> Image Gallery </h3> */}
                    <ImageGallery items={images}
                    showPlayButton = {false}
                    showFullscreenButton = {true}/> 
                </div>
                <h2> About</h2>
                <div className="ppchild">
                    <div className="ppchild">
                        <h3>Description:</h3>
                        <p>Dominion at Oak Ridge Ranch is a 2,370 acre development by Dominion Properties. Excellent views of the Texas Hill Country. The Rolling Oaks Retreat offers 100 acres of rolling terrain. The Ranch has been cleared of most of the cedar. A build site overlooking a hilltop across the property has been cleared. A Draw runs through the middle of the ranch providing great cover for game and elevation change that is hard to find. There is an automatic remote control entry system in place for good security. The easement road inside the ranch is paved. The ranch is loaded with Live Oak with scattered Cedar that provides excellent cover for the numerous game animals. ATV trails meander throughout the ranch giving access to all parts of the ranch.</p>
                    </div>
                    <div className="ppchild">
                        <h3 >Location:</h3>
                        <p>Located Dominion at Oak Ridge Ranch S/D in North Western Kimble County, approximately 20 miles NW of Junction, 2 hours from San Antonio, 5 hours from Houston, and 3 hours from Austin, TX. Access is provided by county roads along with a paved, private road.</p>
                    </div> 
                    <div className="ppchild">
                        <h3>Wildlife:</h3>
                        <p>Managed populations of native and exotic game animals roam the 2,370 +/-.  The ranch is loaded with exotics such as axis, fallow, blackbuck, aoudad, and native whitetail, turkeys, and hogs. A game management program has been established on this new ranch to ensure the quality and quantity of the native game and the exotics.</p>
                    </div>                      
                </div>
            </div>
     );
}
 
export default RollingOaksPage;