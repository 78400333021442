import "../PropertyPage.css"
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css"

//photo imports
import BackPorchView from "./TurtleCreekAssets/BackPorchView.jpg"
import Barn1 from "./TurtleCreekAssets/Barn1.jpg"
import Barn2 from "./TurtleCreekAssets/Barn2.jpg"
import Barn3 from "./TurtleCreekAssets/Barn3.jpg"
import BBQArea from "./TurtleCreekAssets/BBQArea.jpg"
import BBQArea2 from "./TurtleCreekAssets/BBQArea2.jpg"
import Creek1 from "./TurtleCreekAssets/Creek1.jpg"
import Creek2 from "./TurtleCreekAssets/Creek2.jpg"
import Creek3 from "./TurtleCreekAssets/Creek3.jpg"
import Creek4 from "./TurtleCreekAssets/Creek4.jpg"
import FirePit from "./TurtleCreekAssets/FirePit.jpg"
import House1 from "./TurtleCreekAssets/House1.jpg"
import House2 from "./TurtleCreekAssets/House2.jpg"
import House3 from "./TurtleCreekAssets/House3.jpg"
import Kitchen from "./TurtleCreekAssets/Kithcen.jpg"
import Kitchen2 from "./TurtleCreekAssets/Kitchen2.jpg"
import Porch1 from "./TurtleCreekAssets/Porch1.jpg"
import Porch2 from "./TurtleCreekAssets/Porch2.jpg"
import Stairs from "./TurtleCreekAssets/Stairs.jpg"
import Stairs2 from "./TurtleCreekAssets/Stairs2.jpg"




const TurtleCreekPage = () => {

    const images = [
        { 
            original: FirePit,
            thumbnail: FirePit,
        },

        {
            original: BBQArea,
            thumbnail: BBQArea,
        },
        {
            original: BBQArea2,
            thumbnail: BBQArea2,
        },

        { 
            original: Creek1,
            thumbnail: Creek1,
        },

        {
            original: Creek2,
            thumbnail: Creek2,
        },
        {
            original: Creek3,
            thumbnail: Creek3,
        },
        {   
            original: Creek4,
            thumbnail: Creek4,
        },
        { 
            original: Stairs,
            thumbnail: Stairs,
        },

        {
            original: Stairs2,
            thumbnail: Stairs2,
        },
        {
            original: House1,
            thumbnail: House1,
        },
        { 
            original: House2,
            thumbnail: House2,
        },

        {
            original: House3,
            thumbnail: House3,
        },
        {
            original: Porch1,
            thumbnail: Porch1,
        },
        {
            original: Porch2,
            thumbnail: Porch2,
        },
        {
            original: BackPorchView,
            thumbnail: BackPorchView,
        },
        {
            original:Kitchen,
            original:Kitchen,
        },
        {
            original: Kitchen2,
            thumbnail: Kitchen2,
        },
        {
            original: Barn1,
            thumbnail: Barn1,
        },
        {
            original: Barn2,
            thumbnail: Barn2,
        },
        {
            original: Barn3,
            thumbnail: Barn3,
        },
    ]

    return ( 
        <div className = "ppcontainer">
            <div className="ppchild propertynameandloc">
                <h1 class="decorated-title">Turtle Creek</h1>
                <p>22 Acres in Kerr County</p>
            </div>

            <div>
                <h2> Photo Gallery </h2>
                    <div className="ppchild">
                        {/* <h3 className="imagechild"> Image Gallery </h3> */}
                        <ImageGallery items={images}
                        showPlayButton = {false}
                        showFullscreenButton = {true}/> 
                    </div>
                <h2> About </h2>
                <div className="ppchild">
                    <div className="ppchild">
                        <h3>Description:</h3>
                        <p>Approximately 22.4 acres with frontage on gorgeous Turtle Creek.  There is a large concrete dam on the creek which backs up a lake for swimming, kayaking, and fishing.  The lake was cleaned out in 2023 down to the solid rock bottom.   The banks are lined with large Cypress trees and water is clear.  There is a very nice rock BBQ pavilion next to the water which is great for relaxing and entertaining.   10 acres of the property is flat creek bottom coastal pasture which is ideal for horses or livestock.  There is also great potential for a vineyard or fruit tree orchard.  The balance of the property is higher elevation with good views of the property and surrounding hills.   There is good tree coverage consisting of mostly Live Oak, Pecan, and Elm trees.</p>
                    </div>
                    <div className="ppchild">
                        <h3 >Location:</h3>
                        <p>912 Center Point River Road, Center Point, TX  78010.  From Kerrville take Hwy 173 south then east on paved Center Point River Road.  Approximately 5  miles from City limits of Kerrville.</p>
                    </div> 
                    <div className="ppchild">
                        <h3 >Improvements:</h3>
                        <p>The majority of all improvements have been built within the last 3 years.</p>
                        <p>Main Home:  New custom home approximately 4,884 sq. ft. (KCAD) was completed in 2022.  The home is two stories with one large bedroom and loft area upstairs.  There are a total of 3 large bedrooms with walk in closets and 3 full baths.  There is one large office which could be used as a fourth bedroom and an extra large pantry/utility area.  Custom home upgrades include 2x6 stud construction, standing seam metal roof, extra-large wood burning fireplace, 15’ cathedral ceilings in the master bedroom and office, 25’ cathedral ceiling in the great room, 10’ ceilings in all other rooms, large cedar support beams, 14’ Big Ass Fan installed in the great room, and granite counter tops in the kitchen, bathrooms, and utility room.  Gas on demand hot water system and gas for the stove top.  The kitchen appliances are all GE Monogram.  The home also has 1,300 sq. ft. +/- of covered porches with large cedar posts and an extra large attached garage.</p>
                        <p>Other Improvements:   Two car separate garage with two electric doors.   50’x 35’ livestock storage barn. 20,000 sq. ft. of concrete driveway.  Large concrete dam on the creek and a covered rock BBQ pavilion on the creek.</p>
                        <p>Water:   600’ deep water well with new 1.5hp pump installed in December 2022.  10,000 gallon concrete water storage tank for well water.  Creek water system with new 5hp variable speed pump installed in 2023.  New irrigation system around main home installed in 2023.</p>
                    </div>
                    <div className="ppchild">
                        <h3 >Game:</h3>
                        <p>Native Whitetail, Axis Deer, Turkey, Turtles and Bass in the creek</p>
                    </div>                      
                </div>
            </div> 
        </div>
     );
}
 
export default TurtleCreekPage;