import "../PropertyPage.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

//Map Imports:
import Survey from "./PortAransasAssets/638survey.jpg";

//Image Imports
import BackYardView1PA from "./PortAransasAssets/BackYardView1PA.jpeg"
import BackYardView2PA from "./PortAransasAssets/BackYardView2PA.jpeg"
import BackYardView3PA from "./PortAransasAssets/BackYardView3PA.jpeg"
import BackYardView4PA from "./PortAransasAssets/BackYardView4PA.jpeg"
import BackYardView5PA from "./PortAransasAssets/BackYardView5PA.jpeg"
import Fish1PA from "./PortAransasAssets/Fish1PA.jpeg"
import Fish2PA from "./PortAransasAssets/Fish2PA.jpeg"
import Fish3PA from "./PortAransasAssets/Fish3PA.jpeg"
import Fish4PA from "./PortAransasAssets/Fish4PA.jpeg"
import FrontYardView1PA from "./PortAransasAssets/FrontYardView1PA.jpeg"
import Garage1PA from "./PortAransasAssets/Garage1PA.jpeg"
import Garage2PA from "./PortAransasAssets/Garage2PA.jpeg"
import KitchenPA from "./PortAransasAssets/KitchenPA.jpeg"
import LivingRoom1PA from "./PortAransasAssets/LivingRoom1PA.jpeg"
import LivingRoom2PA from "./PortAransasAssets/LivingRoom2PA.jpeg"
import MasterBedroomPA from "./PortAransasAssets/MasterBedroomPA.jpeg"
import SmallLivingRoom1PA from "./PortAransasAssets/SmallLivingRoom1PA.jpeg"
import SmallLivingRoom2PA from "./PortAransasAssets/SmallLivingRoom2PA.jpeg"






const PortAransasPage = () => {

    const maps = [
        {
            original: Survey,
            thumbnail: Survey,
        }
    ];

    const images = [
        {
            original: BackYardView1PA,
            thumbnail:BackYardView1PA,
        },
        {
            original: BackYardView2PA,
            thumbnail:BackYardView2PA,
        },
        {
            original: BackYardView3PA,
            thumbnail:BackYardView3PA,
        },
        {
            original: BackYardView4PA,
            thumbnail:BackYardView4PA,
        },
        {
            original: BackYardView5PA,
            thumbnail:BackYardView5PA,
        },
        {
            original: FrontYardView1PA,
            thumbnail: FrontYardView1PA,
        },
        {
            original: LivingRoom1PA,
            thumbnail: LivingRoom1PA,
        },
        {
            original: LivingRoom2PA,
            thumbnail: LivingRoom2PA,
        },
        {
            original: SmallLivingRoom1PA,
            thumbnail: SmallLivingRoom1PA,
        },
        {
            original: SmallLivingRoom2PA,
            thumbnail: SmallLivingRoom2PA,
        },
        {
            original: KitchenPA,
            thumbnail: KitchenPA,
        },
        {
            original: MasterBedroomPA,
            thumbnail: MasterBedroomPA,
        },
        {
            original: Garage1PA,
            thumbnail: Garage1PA,
        },
        {
            original: Garage2PA,
            thumbnail: Garage2PA,
        },
        {
            original: Fish1PA,
            thumbnail: Fish1PA,
        },
        {
            original: Fish2PA,
            thumbnail: Fish2PA,
        },
        {
            original: Fish3PA,
            thumbnail: Fish3PA,
        },
        {
            original: Fish4PA,
            thumbnail: Fish4PA,
        },
    ]

    

    return ( 
        <div>

            <div className = "ppcontainer">

                <div className="ppchild propertynameandloc">
                    <h1 class="decorated-title">Port Aransas House</h1>
                    <p>683 Shorline Circle Port Aransas, Texas 78373</p>
                </div>
                <h2> Photo Gallery </h2>
                <div className="ppchild">
                    {/* <h3 className="imagechild"> Image Gallery </h3> */}
                    <ImageGallery items={images}
                    showPlayButton = {false}
                    showFullscreenButton = {true}/> 
                </div>
 
                <div>
                        <h2> About </h2>
                        <div className="ppchild">
                            <div className="ppchild">
                                <h3>Location Amenitites:</h3>
                                <br/>
                                <p>The home is located in a private gated security community known as Porpoise Point, in Port Aransas, Tx.</p>
                                <br/>
                                <p>The property has direct water frontage on the shipping channel approximately one mile inside the Port A Jetty.   The tides bring a big fresh gulf current by the property every day.   Fishing off the pier can be outstanding for redfish, trout, flounder, and sheepshead.  This honey hole is well known for large schools of redfish throughout the year.  Dolphin can be viewed all day from the swimming pool, pier, or from the dining table.  Green lights have been installed under the pier for night time fishing.</p>                
                            </div>
                            <div className="ppchild">
                                <h3 >House amenities:</h3>
                                <br/>
                                <p>Custom home built in 2006 by Kypkie Builders. The home is approximately 3,200sf with 4 bedrooms and 4 full baths.   The home was designed in 3 separate buildings for efficiency.   The main building has the master bedroom and one guest bedroom.  Then there are two separate casitas each with a bedroom and full bath.   All bedrooms have walk in closets.   Other amenities of the main home include, high impact storm windows, security system, stained concrete floors, GE Monogram appliances including an ice maker in the kitchen. Large utility room with extra refrigerator and lots of storage.</p>                
                                <br/>
                                <p>The main home is offered fully furnished with high quality furnishings.  There are 5 televisions including one television in each bedroom. </p>
                            </div>
                            <div className="ppchild">
                                <h3 >Garage details:</h3>
                                <br/>
                                <p>The estate includes a large custom garage with approximately 2,000sf.   The garage has 3 overhead electric doors accommodating two full size vehicles and a large bay for storing a bay boat or RV.  The garage has lots of storage, work - craft maintenance counter, and a tackle storage area for fishing gear and water toys.</p>                
                            </div>  
                            <div className="ppchild">
                                <h3>Outside property Amenities:</h3>
                                <br/>
                                <p>Custom salt water swimming pool.</p>
                                <p>New Jacuzzi installed in 2021.</p>
                                <p>Elaborate covered fishing pier with Brazilian hardwood decks and rails.</p>
                                <p>Fish cleaning station.</p>
                                <p>Irrigation system.</p>
                                <p>Custom water bulk head with granite rock.</p>
                                <p>Custom fencing built from Brazilian Hardwood.</p>
                                <p>Elaborate landscaping with lots of big palm trees.</p>
                                <p>Pebble wash drive ways and decking all around the property.</p>
                                <p>Fully stocked with outdoor patio furnishings made from recycled plastic for maximum durability.</p>
                            </div>                     
                        </div>
                    <h2> Video Gallery </h2>            
                        <div className = "videocontainer ppchild" >

                            <iframe className="vcchild"
                                src={`https://www.youtube.com/embed/aNGDouJ75l0`}
                                frameborder="0" allowFullScreen
                                width="600" height="350">
                            </iframe>            
                        </div>
                </div>               
                

                <h2>  Surverys  </h2>   
                <div className="ppchild">
                    <ImageGallery items={maps}
                    showPlayButton = {false}
                    showFullscreenButton = {true}
                    style={{ width: '200px', height: 'auto' }}/> 
                </div>
                </div>


        </div>

        
     );
}
 
export default PortAransasPage;