// General Imports
import { Routes, Route } from "react-router-dom";
import "./App.css";

// Pages Imports
import HomePage from "./pages/HomePage/HomePage";
import LoginPage from "./pages/LoginPage/LoginPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import LaOndaPage from "./pages/LaOnda/LaOnda";

//MRS Page Imports
import TurtleCreekPage from "./pages/TurtleCreek/TurtleCreek";
import PortAransasPage from "./pages/PortAransas/PortAransas";
import RoadCreekPage from "./pages/RoadCreek/RoadCreek";
import RollingOaksPage from "./pages/RollingOaks/RollingOaks";
import MedioValleyPage from "./pages/MedioValley/MedioValley";




// Component Imports
import Navbar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";

// Util Imports
import PrivateRoute from "./utils/PrivateRoute";

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/laonda" element={<LaOndaPage/>} />
        <Route path="/turtlecreek" element={<TurtleCreekPage/>} />
        <Route path="/portaransas" element={<PortAransasPage/>} />
        <Route path="/roadcreek" element={<RoadCreekPage/>} />
        <Route path="/rollingoaks" element={<RollingOaksPage/>} />
        <Route path="/mediovalley" element={<MedioValleyPage/>} />

      </Routes>
    </div>
  );
}

export default App;
